import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LearningView from "@/views/LearningView.vue";
import QuizView from "@/views/QuizView.vue";
import StatsView from "@/views/StatsView.vue";
import LanguageSelectView from "@/views/LanguageSelectView.vue";
import VocListView from "@/views/VocListView.vue";
import LoginView from "@/views/LoginView.vue";
import VocImportView from "@/views/VocImportView.vue";
import {UserService} from "@/service/UserService";
import TextQuizView from "@/views/TextQuizView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/languages/:langUuid/home',
        name: 'home',
        component: HomeView
    },
    {
        path: '/languages/:langUuid/',
        redirect: to => 'home'
    },
    {
        path: '/languages',
        name: 'languages',
        component: LanguageSelectView
    },
    {
        path: '/languages/:langUuid/vocabulary',
        name: 'voc-list',
        component: VocListView
    },
    {
        path: '/languages/:langUuid/vocabulary/import',
        name: 'voc-import',
        component: VocImportView
    },
    {
        path: '/languages/:langUuid/learning',
        name: 'learning',
        component: LearningView
    },
    {
        path: '/languages/:langUuid/quiz',
        name: 'quiz',
        component: TextQuizView
    },
    {
        path: '/languages/:langUuid/simple-quiz',
        name: 'simple-quiz',
        component: QuizView
    },
    {
        path: '/languages/:langUuid/stats',
        name: 'stats',
        component: StatsView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            requiresAuth: false
        },
    },

    {
        path: '/:pathMatch(.*)*',
        redirect: '/languages',
    }
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    const requiresNoAuth = to.matched.some((x) => x.meta.requiresAuth === false);

    // Der Zugriff auf den Store funktioniert einfach überhaupt nicht.
    const isPossiblyLoggedin = (localStorage.getItem("access_token") as string)?.length > 0 // !!store.state.user;

    if (!from.name && to.name != "login") {
        // possibly page was reloaded
        // now check the backend if the token is still valid
        try {
            const user = await UserService.get().getUser();
            if (user) {
                next();
            } else {
                next('/login');
            }
            return;
        } catch (err) {
            // not logged in;
            next("/login");
            return;
        }
    }

    if (requiresNoAuth) {
        next();
    } else if (isPossiblyLoggedin) {
        next();
    } else {
        if (!window.location.href.includes("/login")) {
            sessionStorage.setItem("redirect_url", window.location.href);
        }
        next ("/login");
    }
});


export default router
