
import {Options, Vue} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import {Language, Vocabulary, VocabularyRangeStore, VocabularyWithStats} from "@/model/model";
import VocabPanel from "@/components/VocabPanel.vue";
import {VocService} from "@/service/VocService";
import ProgressBar from "@/components/ProgressBar.vue";
import FinishScreen from "@/components/FinishScreen.vue";
import LearningSuccessScreen from "@/components/LearningSuccessScreen.vue";
import QuizSuccessScreen from "@/components/QuizSuccessScreen.vue";
import FlipCard from "@/components/FlipCard.vue";
import VocabEdit from "@/components/VocabEdit.vue";
import ModalScreen from "@/components/ModalScreen.vue";
import {AppSnackbarService} from "@/service/AppSnackbarService";
import {InjectReactive} from "vue-property-decorator";
import VocabStats from "@/components/VocabStats.vue"; // @ is an alias to /src

const defaultLockState = {
  showAnswerIslocked: true,
  btnNoIsLocked: true,
  btnMiddleIsLocked: true,
  btnYesIsLocked: true,
}

@Options({
  components: {
    VocabStats,
    FlipCard,
    QuizSuccessScreen,
    LearningSuccessScreen,
    FinishScreen,
    ProgressBar,
    VocabPanel,
    VocabEdit,
    ModalScreen,
  },
})
export default class QuizView extends Vue {

  private vocs: VocabularyWithStats[] = [];
  private index = 0;
  private submittedIndex = -1;
  private showAnswer = false;
  private askedForLang1 = true;
  private showFinishScreen = false;
  private language: Language = {label: '', uuid: '', short_label_lang1: '', short_label_lang2: '', both_directions: 0};
  private openEdit = false;

  private lockState = defaultLockState;

  @InjectReactive() debugMode!: string
  private timers: number[] = [];

  private stats = {
    succeeded: 0, failed: 0, mixedState: 0, skipped: 0
  }

  get voc1(): string[] {
    if (this.vocs.length < this.index) {
      return [];
    }
    const textField = this.askedForLang1 ? 'text_lang1' : 'text_lang2';
    return this.vocs[this.index][textField].split(',');
  }

  get voc2(): string[] {
    if (!this.showAnswer) {
      return [];
    }

    if (this.vocs.length < this.index) {
      return [];
    }
    const textField = this.askedForLang1 ? 'text_lang2' : 'text_lang1';
    return this.vocs[this.index][textField].split(',');
  }

  showMeTheAnswer(): void {
    if (this.lockState.showAnswerIslocked) return;
    this.showAnswer = true;
    this.timers.push(setTimeout(() => this.lockState.btnNoIsLocked = false, 15000));
    this.timers.push(setTimeout(() => this.lockState.btnMiddleIsLocked = false, 8000));
    this.timers.push(setTimeout(() => this.lockState.btnYesIsLocked = false, 1500));
  }

  next(updateLevel: boolean, levelDirection: -1 | 0 | 1): void {
    if (updateLevel && this.lockState.btnNoIsLocked && levelDirection < 0) {
      return;
    }
    if (updateLevel && this.lockState.btnMiddleIsLocked && levelDirection == 0) {
      return;
    }
    if (updateLevel && this.lockState.btnYesIsLocked && levelDirection > 0) {
      return;
    }

    if (updateLevel && this.submittedIndex < this.index) {
      //do not wait for the answer
      const currentIndex = this.index;
      // VocService.get().modifyLevel(this.vocs[this.index].uuid, levelDirection, this.askedForLang1 ? 1 : 2)
      //     .then(_ => this.submittedIndex = currentIndex)
    }

    if (updateLevel) {
      if (levelDirection > 0) {
        this.stats.succeeded++;
      } else if (levelDirection < 0) {
        this.stats.failed++;
      } else {
        this.stats.mixedState++;
      }
    } else {
      this.stats.skipped++;
    }
    if (this.index < this.vocs.length - 1) {
      this.index++;
      this.initScreen();

      // die richtung der sprache bestimmen
      this.initLanguageDirection();
    } else {
      this.showFinishScreen = true;
    }
  }

  private initScreen() {
    this.timers.forEach(timer => clearTimeout(timer));
    this.timers.length = 0;
    this.showAnswer = false;
    this.lockState = {...defaultLockState};
    this.timers.push(setTimeout(() => this.lockState.showAnswerIslocked = false, 3000));
  }

  private initLanguageDirection() {
    const nextVoc = this.vocs[this.index];
    if (!nextVoc) {
      return;
    }
    if (this.language.both_directions == 0) {
      this.askedForLang1 = true;
      return;
    }
    if (nextVoc.level_lang1 == nextVoc.level_lang2) {
      if (nextVoc.succeeded_lang2 - nextVoc.missed_lang2 == nextVoc.succeeded_lang1 - nextVoc.missed_lang1) {
        this.askedForLang1 = Math.random() < 0.5;
      } else {
        this.askedForLang1 = nextVoc.succeeded_lang2 - nextVoc.missed_lang2 > nextVoc.succeeded_lang1 - nextVoc.missed_lang1;
      }
    } else {
      this.askedForLang1 = nextVoc.level_lang1 < nextVoc.level_lang2;
    }
  }

  async mounted() {
    this.language = await VocService.get().getLanguage(this.$route.params.langUuid as string) || this.language;

    this.vocs = (await VocService.get().getNextQuiz(this.language.uuid, VocabularyRangeStore.load(this.language.uuid)))
        .sort(() => Math.random() - 0.5);

    if (this.vocs?.length == 0) {
      AppSnackbarService.get().show("Noch keine gelernten Vokabeln im Abfragebereich vorhanden.", 'info', 2500);
      this.finish();
    }

    this.index = 0;
    this.initScreen();
    this.initLanguageDirection();
  }

  finish() {
    this.$router.push('./')
  }

  goBack() {
    if (this.index > 0) {
      this.index--;
      this.initScreen();
    }
  }

  private onVocabSaved(vocab: Vocabulary): void {
    this.vocs[this.index] = {...this.vocs[this.index], ...vocab};
    this.openEdit = false;
    AppSnackbarService.get().show("Gespeichert", 'success', 1500);
  }

}
